<template>
  <div class="usuarios-list">
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
        <register-modal>
<!--          <template #default="{on, attrs}">-->
<!--            <v-btn color="success" v-on="on" v-bind="attrs">-->
<!--              <v-icon class="mr-2">mdi-plus</v-icon>-->
<!--              novo-->
<!--            </v-btn>-->
<!--          </template>-->
        </register-modal>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="user.data"
          :search="tableSearch"
          :loading="user.status === 1"
          fixed-header
          item-key=""
          disable-pagination
          hide-default-footer
      >
        <template #item.ativo="{ item }">
          {{ item.ativo ? 'sim' : 'não'}}
        </template>
<!--        <template #item.acoes="{item}">-->
<!--          <v-layout>-->
<!--            <form-modal :data="item">-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="editar">mdi-pencil</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </form-modal>-->
<!--            <delete-modal>-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon class="ml-1" color="error" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="excluir">mdi-delete</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </delete-modal>-->
<!--          </v-layout>-->
<!--        </template>-->
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
// import DeleteModal from "@/components/modals/delete-modal";
// import FormModal from "@/views/database/usuarios/update";
import RegisterModal from "@/views/database/usuarios/register";
export default {
  components: {
    // FormModal,
    // DeleteModal,
    RegisterModal
  },
  data: () => ({
    title: "Usuários",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "E-mail", value: "email" },
      { text: "Celular", value: "celular" },
      { text: "Ativo", value: "ativo" },
      // { text: "Ações", value: 'acoes' },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.database.user;
    }
  },
  mounted() {
    this.$store.dispatch('database/user/getAll', {page: this.$route.query.page ? this.$route.query.page : '1'})
  }
}
</script>

<style lang="scss">

</style>
