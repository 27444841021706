<template>
  <div class="usuarios-form">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-4">{{ title }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="usuario.email" label="E-mail" />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="usuario.celular" label="Celular" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                Preencha os dados do Lead
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="lead.cpf" label="CPF" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="lead.cnpj" label="CNPJ" />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="lead.dataNasc" label="Data Nasc." />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="lead.cidade" label="Cidade" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="lead.uf" label="UF" />
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="mt-8">
            <v-layout justify-end>
              <v-btn color="white" class="mr-4" @click="onClickCancel">Cancelar</v-btn>
              <v-btn color="primary" @click="onClickSave">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Salvar
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>

const usuario = {
  email: '',
  celular: '',
  ativo: ''
}

const lead = {
  cpf: '',
  cnpj: ''
}

export default {
  props: {
    data: {
      type: Object
    },
    onSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    title: 'Cadastrar Dados',
    show: false,
    usuario: {...usuario},
    lead: {...lead}
  }),
  methods: {
    onClickSave() {
      this.onSave();
      this.show = false;
    },
    onClickCancel() {
      this.onCancel();
      this.show = false;
      this.reset();
    },
    reset() {
      this.usuario = {...usuario}
      this.lead = {...lead}
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss">

</style>
